<template>
  <div class="container">
    <tabs>
      <tab name="Chat" :selected="true">
        <chat-window
          height="100vh"
          :current-user-id="currentUserId"
          :rooms="rooms"
          :rooms-loaded="roomsLoaded"
          :messages-loaded="messagesLoaded"
          :single-room="singleRoom"
          :messages="messages"
          :socket="socket"
          :show-files="showFiles"
          :show-audio="showAudio"
          @send-message="sendMessage"
          @fetch-messages="fetchMessages"
          @send-message-reaction="sendMessageReaction"
        />
      </tab>
      <tab name="Video">
        <iframe
          :src="conferenceUrl"
          width="1200"
          height="800"
          allow="camera;microphone"
        ></iframe>
      </tab>
    </tabs>
  </div>
</template>

<script>
import ChatWindow from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";

import Tabs from "./components/Tabs.vue";
import Tab from "./components/Tab.vue";

//Zu Testzwecken Userinfos aus URL laden
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("token");

const fetchOptions = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

export default {
  components: {
    ChatWindow,
    Tabs,
    Tab,
  },
  data() {
    return {
      socket: {},
      conferenceUrl: "",
      rooms: [],
      currentUserId: 0,
      messages: [],
      roomsLoaded: true,
      messagesLoaded: false,
      singleRoom: true,
      selectedTab: "CHAT",
      showFiles: false,
      showAudio: false,
    };
  },

  created() {
    /*fetch("http://100.82.140.79:8081/conference?rid=" + roomID)
      .then((response) => response.json())
      .then((data) => (this.conferenceUrl = data.url))
      .catch((error) => console.error(error));*/

    fetch("/api/v1/cfg", fetchOptions)
      .then((response) => response.json())
      .then((data) => {
        this.rooms = data.rooms;
        this.currentUserId = data.user_info.UserID;
        this.roomId = data.user_info.RoomID;
        this.userName = data.user_info.Name;
      })
      .catch((error) => console.error(error));

    this.listenForMessages();
  },

  methods: {
    setMessageReaction(messageId, reaction, remove, userId) {
      console.log(remove);
      let uid = userId ? userId : this.currentUserId;
      let message = this.messages.find((message) => message._id === messageId);
      if (message) {
        if (message.reactions === undefined) {
          this.$set(message, "reactions", {});
        }
        let ids = [uid];
        if (message.reactions[reaction["unicode"]] && !message.reactions[reaction["unicode"]].includes(uid)) {
          ids = [...ids, ...message.reactions[reaction["unicode"]]];
        }
        this.$set(message.reactions, reaction["unicode"], ids);
      }
    },
    sendMessageReaction({ roomId, messageId, reaction, remove }) {
      console.log(roomId);
      this.socket.send(
        JSON.stringify({
          messageId: messageId,
          reaction: reaction,
          remove: remove,
          type: "sendMessageReaction",
        })
      );
    },
    fetchMessages({ room, options }) {
      let ts = "";
      console.log(room);

      if (this.messages.length > 0 && !options) {
        ts = this.messages[0].timestamp;
      }

      fetch("/api/v1/messages?ts=" + ts, fetchOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          this.messages = [...data, ...this.messages];
          if (data.length == 0) {
            this.messagesLoaded = true;
          }
        })
        .catch((error) => console.error(error));
    },
    sendMessage(
      { content, roomId, files, replyMessage, username },
      senderId = this.currentUserId
    ) {
      console.log(files);
      console.log(username);
      console.log(roomId);

      const message = {
        _id: 1,
        senderId: senderId,
        username: this.userName,
        distributed: true,
        disableReactions: false,
        replyMessage: replyMessage,
        content,
        timestamp: new Date(),
      };

      this.socket.send(
        JSON.stringify({
          message: message,
          type: "sendMessage",
        })
      );
    },
    listenForMessages() {
      let that = this;

      fetch("/api/v1/authWS", fetchOptions)
        .then((response) => response.text())
        .then((data) => {
          that.socket = new WebSocket(
            "wss://collabdev.edupool.cloud/api/v1/ws?token=" + data
          );
          that.socket.onmessage = function (event) {
            let data = JSON.parse(event.data);
            switch (data["type"]) {
              case "sendMessageReaction":
                that.setMessageReaction(
                  data["messageId"],
                  data["reaction"],
                  data["remove"],
                  data["userId"],
                );
                break;
              case "sendMessage":
                that.messages = [...that.messages, data.message];
                break;
              case "joinRoom":
                var room = that.rooms[0];
                if (room) {
                  var user = room.users.find(
                    (user) => user._id === data.user.id
                  );
                  if (!user) {
                    room.users.push(data.user);
                  }
                }
                break;
              case "leaveRoom":
                console.log(data);
                break;
            }
          };
          that.socket.onerror = function () {
            that.socket.close();
          };
          that.socket.onopen = function () {
            clearInterval(that.timerId);
            that.socket.onclose = function () {
              that.timerId = setInterval(() => {
                that.listenForMessages();
              }, 10000);
            };
          };
        })
        .catch((error) => console.error(error));
    },
  },
};
</script>
